import React from 'react';
import Header from './header/header';
import Footer from './footer/footer';

const MainLayout = ({ children, onSectionNavigate = () => {} }) => {
  return (
    <div>
      <Header onSectionNavigate={onSectionNavigate} />
      <main className="mt-32">{children}</main>
      <Footer />
    </div>
  );
};

export default MainLayout;
