import React, { useState } from 'react';

function HomeFaq() {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const accordionData = [
    {
      heading:
        "Pourquoi est il important pour mon entreprise d'avoir un site web ? ",
      content:
        "Un site web permet à votre entreprise d'avoir une présence en ligne, qui est essentielle pour toute entreprise moderne. Cela permet à vos clients d'en apprendre plus sur vous et peut permettre d'attirer de nouveaux clients.",
    },
    {
      heading: 'Combien de temps faut il pour créer un site web ? ',
      content:
        "La réalisation d'un site peut durer de quelques semaines à plusieurs mois. Cela dépend de la taille de votre projet. En général un site vitrine ne prend pas plus de 4 semaines à êtré développé.",
    },
    {
      heading: "À l'aide de quels outils je vais développer votre site web ?",
      content:
        "Les outils sont choisis en fonction du projet, par exemple un site vitrine sera fait soit avec wordpress, soit à l'aide d'un framework front-end tel que React. Les sites plus complexes eux demandent souvent l'utilisation de langages de programmation tels que PHP ou Python;",
    },
  ];

  return (
    <section className="lg:px-28">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <h1 className="mb-6 ms-2 mt-16 w-full border-l-4 border-midBlue pl-2 text-xl font-bold text-zinc-900 md:text-3xl lg:text-4xl">
          Questions fréquentes
        </h1>
        <div className="accordion-group" data-accordion="default-accordion">
          {accordionData.map((item, index) => (
            <div
              key={index}
              className={`accordion ${activeIndex === index ? 'accordion-active:bg-indigo-50 accordion-active:border-indigo-600' : ''} mb-8 rounded-xl border border-solid border-gray-300 p-4 lg:p-4`}
              id={`heading-${index}`}
            >
              <button
                className="accordion-toggle accordion-active:font-medium accordion-active:text-indigo-600 group inline-flex w-full items-center justify-between text-left text-lg font-normal leading-8 text-gray-900 transition duration-500 hover:text-midBlue"
                aria-controls={`collapse-${index}`}
                onClick={() => toggleAccordion(index)}
              >
                <h5>{item.heading}</h5>
                <svg
                  className={`accordion-active:text-indigo-600 ${activeIndex === index ? 'hidden' : 'block'} h-6 w-6 origin-center text-gray-900 transition duration-500 group-hover:text-midBlue`}
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6 12H18M12 18V6"
                    stroke="currentColor"
                    strokeWidth="1.6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                </svg>
                <svg
                  className={`accordion-active:text-indigo-600 ${activeIndex === index ? 'block' : 'hidden'} h-6 w-6 text-gray-900 transition duration-500 group-hover:text-midBlue`}
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6 12H18"
                    stroke="currentColor"
                    strokeWidth="1.6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                </svg>
              </button>
              <div
                id={`collapse-${index}`}
                className="accordion-content w-full overflow-hidden pr-4"
                aria-labelledby={`heading-${index}`}
                style={{
                  maxHeight: activeIndex === index ? '250px' : '0',
                  transition: 'max-height 0.5s ease',
                }}
              >
                <p className="text-base font-normal leading-6 text-gray-900">
                  {item.content}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default HomeFaq;
