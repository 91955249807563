import React from 'react';
import { Tabs } from '../../../component/tabs';
import { Link } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';

function HomeProjects() {
  const tabs = [
    {
      title: 'Back-end',
      value: 'tab2',
      content: (
        <div className=" flex justify-center p-4">
          <div className="mr-4 flex w-1/2 flex-col ">
            <div className="w-full">
              <Link to="/project/extranet">
                <img
                  className="transform p-2 transition-transform hover:scale-105 hover:brightness-75"
                  src="assets/images/projects/back/extranet/extranet-mockup.png"
                  alt="image du projet d'extranet pour le suivi client d'une entreprise"
                />
              </Link>
              <p className="ms-2 text-sm italic text-darkBlue/80 md:mb-8 lg:mb-8">
                Extranet de suivi clients
              </p>
            </div>

            <div className="w-full">
              <Link to="/project/sport-blog">
                <img
                  className="transform p-2 transition-transform hover:scale-105 hover:brightness-75"
                  src="assets/images/projects/back/sport/blog_sport-mockup.png"
                  alt="image du projet d'extranet pour le suivi client d'une entreprise"
                />
              </Link>
              <p className="ms-2 text-sm italic text-darkBlue/80 md:mb-8 lg:mb-8">
                Blog de sport
              </p>
            </div>
          </div>
          <div className="mr-4 flex w-1/2 flex-col ">
            <div className="w-full">
              <Link to="/project/quiz">
                <img
                  className="transform p-2 transition-transform hover:scale-105 hover:brightness-75"
                  src="assets/images/projects/back/quiz/quiz-mockup.png"
                  alt="image du projet de quiz en ligne"
                />
              </Link>
              <p className="ms-2 text-sm italic text-darkBlue/80 md:mb-8 lg:mb-8">
                Quiz en ligne
              </p>
            </div>

            <div className="w-full">
              <Link to="/project/robindesbus">
                <img
                  className="transform p-2 transition-transform hover:scale-105 hover:brightness-75"
                  src="assets/images/projects/back/robindesbus/robindesbus-mockup.png"
                  alt="image du site pour une association de transport "
                />
              </Link>
              <p className="ms-2 text-sm italic text-darkBlue/80 md:mb-8 lg:mb-8">
                Site pour une association de transports
              </p>
            </div>
          </div>
        </div>
      ),
    },
    {
      title: 'Front-end',
      value: 'tab1',
      content: (
        <div className="flex justify-center p-4">
          <div className="mr-4 flex w-1/2 flex-col ">
            <div className="w-full">
              <Link to="/project/landing-saas">
                <img
                  className="transform p-2 transition-transform hover:scale-105 hover:brightness-75"
                  src="assets/images/projects/front/landing-saas/landing-saas-mockup.png"
                  alt="image du projet d'intégration d'une maquette pour une landing page d'un saas"
                />
              </Link>
              <p className="ms-2 text-sm italic text-darkBlue/80 md:mb-8 lg:mb-8">
                Page d'accueil d'un logiciel
              </p>
            </div>
            <div className="w-full">
              <Link to="/project/orm">
                <img
                  className="transform p-2 transition-transform hover:scale-105 hover:brightness-75"
                  src="assets/images/projects/front/orm/orm-mockup.png"
                  alt="image du projet d'intégration d'une maquette pour une landing page d'un saas"
                />
              </Link>
              <p className="ms-2 text-sm italic text-darkBlue/80 md:mb-8 lg:mb-8">
                Design d'un dashboard pour un ORM
              </p>
            </div>
          </div>
          <div className="w-[31vw] md:w-[29.4vw]">
            <div>
              <Link to="/project/imc-calculator">
                <img
                  className="transform p-2 transition-transform hover:scale-105 hover:brightness-75"
                  src="assets/images/projects/front/imc/imc-mockup.png"
                  alt="image du projet d'une calculatrice d'IMC"
                />
              </Link>
              <p className="ms-2 text-sm italic text-darkBlue/80 md:mb-8 lg:mb-8">
                Calculatrice d'IMC
              </p>
            </div>
            <div className="">
              <Link to="/project/pomodoro">
                <img
                  className="transform p-2 transition-transform hover:scale-105 hover:brightness-75"
                  src="assets/images/projects/front/pomodoro/pomodoro-mockup.png"
                  alt="image du projet de timer pomodoro"
                />
              </Link>
              <p className="ms-2 text-sm italic text-darkBlue/80 md:mb-8 lg:mb-8">
                Timer pomodoro
              </p>
            </div>
          </div>
        </div>
      ),
    },

    {
      title: 'Projets scolaires',
      value: 'tab3',
      content: (
        <div className="p-4">
          <p>
            Pour des raisons de confidentialité, tous les projets réalisés
            durant ma formation n'ont pas d'images d'illustration. Néanmoins
            vous pouvez tout de même cliquer dessus pour avoir plus de détails
          </p>
          <div className="flex flex-col lg:flex-row lg:space-x-4 ">
            <div className="mt-8 h-full w-full rounded-lg bg-darkBlue px-6 py-3 transition-transform hover:scale-105 hover:brightness-75 lg:mt-4 lg:w-1/2">
              <h2 className="text-xl font-semibold text-white">
                Application mobile de partage de contenu 3D
              </h2>
              <div className="mt-4 flex flex-wrap space-x-4">
                <p className="rounded-full bg-white px-2 py-1 text-sm text-darkBlue/80">
                  Spring boot
                </p>
                <p className="rounded-full bg-white px-2 py-1 text-sm text-darkBlue/80">
                  Java
                </p>
                <p className="rounded-full bg-white px-2 py-1 text-sm text-darkBlue/80">
                  Android
                </p>
              </div>
            </div>
            <div className="mt-4 h-full w-full rounded-lg bg-darkBlue px-6 py-3 transition-transform hover:scale-105 hover:brightness-75 lg:w-1/2">
              <h2 className="text-xl font-semibold text-white">
                Application web pour étudiants infirmiers
              </h2>
              <div className="mt-4 flex flex-wrap space-x-4">
                <p className="rounded-full bg-white px-2 py-1 text-sm text-darkBlue/80">
                  PHP
                </p>
                <p className="rounded-full bg-white px-2 py-1 text-sm text-darkBlue/80">
                  JS
                </p>
              </div>
            </div>
          </div>
        </div>
      ),
    },
  ];

  return (
    <div className="px-4 py-8 md:px-8 lg:px-36">
      <h1 className="mb-6 ms-2 mt-16 w-full border-l-4 border-midBlue pl-2 text-xl font-bold text-zinc-900 md:text-3xl lg:text-4xl">
        Projets
      </h1>
      <Tabs
        tabs={tabs}
        containerClassName="space-x-4 mb-8"
        activeTabClassName="bg-lightBlue dark:bg-darkBlue"
        tabClassName="px-4 py-2 rounded-full border hover:bg-lightBlue"
        contentClassName="mt-4"
      />
    </div>
  );
}

export default HomeProjects;
