import React from 'react';
import ComputerIcon from '@mui/icons-material/Computer';
import EditIcon from '@mui/icons-material/Edit';
import BuildIcon from '@mui/icons-material/Build';

function HomeServices() {
  return (
    <div className="px-4 md:px-36 md:px-8 lg:px-36">
      <h1 className="mb-6 ms-2 mt-16 w-full border-l-4 border-midBlue pl-2 text-xl font-bold text-zinc-900 md:text-3xl lg:text-4xl">
        Services proposés
      </h1>
      <div className="relative flex	h-fit flex-wrap justify-between ">
        <div className="mb-4 h-full w-full rounded-xl bg-lightBlue p-4 shadow-[0_3px_10px_rgb(0,0,0,0.2)] md:mb-0 md:w-1/4 lg:w-[25vw]">
          <div className="flex items-center space-x-3">
            <div className="h-fit w-fit rounded-full bg-darkBlue p-3 text-white">
              <ComputerIcon style={{ fontSize: 30 }} />
            </div>
            <h3 className="text-xl font-semibold">Développement web</h3>
          </div>
          <p className="ms-2 mt-4 rounded-lg px-4 py-2 hover:bg-zinc-100">
            Sites internet
          </p>
          <p className="ms-2 rounded-lg px-4 py-2 hover:bg-zinc-100">
            Sites vitrines
          </p>
          <p className="ms-2 rounded-lg px-4 py-2 hover:bg-zinc-100">
            Sites E-commerce
          </p>
          <p className="ms-2 rounded-lg px-4 py-2 hover:bg-zinc-100">
            Sites sur mesure
          </p>
        </div>

        <div className="h-100 mb-4 w-full rounded-xl bg-lightBlue p-4 shadow-[0_3px_10px_rgb(0,0,0,0.2)] md:mb-0 md:w-1/4 lg:w-[25vw]">
          <div className="flex items-center space-x-3">
            <div className="h-fit w-fit rounded-full bg-darkBlue p-3 text-white">
              <EditIcon style={{ fontSize: 30 }} />
            </div>
            <h3 className="text-xl font-semibold">Design web</h3>
          </div>
          <p className="ms-2 mt-4 rounded-lg px-4 py-2 hover:bg-zinc-100">
            Maquettage site web
          </p>
          <p className="ms-2 rounded-lg px-4 py-2 hover:bg-zinc-100">
            Maquettage application mobile
          </p>
        </div>

        <div className="h-100 mb-4 w-full rounded-xl bg-lightBlue p-4 shadow-[0_3px_10px_rgb(0,0,0,0.2)] md:mb-0 md:w-1/4 lg:w-[25vw]">
          <div className="flex items-center space-x-3">
            <div className="h-fit w-fit rounded-full bg-darkBlue p-3 text-white">
              <BuildIcon style={{ fontSize: 30 }} />
            </div>
            <h3 className="text-xl font-semibold">Maintenance</h3>
          </div>
          <p className="ms-2 mt-4 rounded-lg px-4 py-2 hover:bg-zinc-100">
            Hébergement de vos sites
          </p>
          <p className="ms-2 rounded-lg px-4 py-2 hover:bg-zinc-100">
            Maintenance (petites changements, corrections de bugs, ...)
          </p>
        </div>
      </div>
    </div>
  );
}

export default HomeServices;
