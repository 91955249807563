'use client';

import { useState } from 'react';
import { motion } from 'framer-motion';
import { cn } from '@utils/cn'; // Assurez-vous que le chemin est correct

export const Tabs = ({
  tabs: propTabs,
  containerClassName,
  activeTabClassName,
  tabClassName,
  contentClassName,
}) => {
  const [active, setActive] = useState(propTabs[0]);
  const [tabs, setTabs] = useState(propTabs);

  const moveSelectedTabToTop = (idx) => {
    const newTabs = [...propTabs];
    const selectedTab = newTabs.splice(idx, 1);
    newTabs.unshift(selectedTab[0]);
    setTabs(newTabs);
    setActive(newTabs[0]);
  };

  const [hovering, setHovering] = useState(false);

  return (
    <>
      <div
        className={cn(
          'no-visible-scrollbar relative flex w-full max-w-full flex-row items-center justify-start overflow-auto [perspective:1000px] sm:overflow-visible',
          containerClassName
        )}
      >
        {propTabs.map((tab, idx) => (
          <button
            key={tab.title}
            onClick={() => {
              moveSelectedTabToTop(idx);
            }}
            onMouseEnter={() => setHovering(true)}
            onMouseLeave={() => setHovering(false)}
            className={cn('relative rounded-full px-4 py-2', tabClassName)}
            style={{
              transformStyle: 'preserve-3d',
            }}
          >
            {active.value === tab.value && (
              <motion.div
                layoutId="clickedbutton"
                transition={{ type: 'spring', bounce: 0.3, duration: 0.6 }}
                className={cn(
                  'absolute inset-0 rounded-full bg-lightBlue dark:bg-zinc-800 ',
                  activeTabClassName
                )}
              />
            )}

            <span
              className={cn('relative block', {
                'text-zinc-900 dark:text-white': active.value === tab.value,
                'text-zinc-500 dark:text-zinc-400': active.value !== tab.value,
              })}
            >
              {tab.title}
            </span>
          </button>
        ))}
      </div>
      <FadeInDiv
        tabs={tabs}
        active={active}
        key={active.value}
        hovering={hovering}
        className={cn('mt-4', contentClassName)} // Ajuster la marge selon vos besoins
      />
    </>
  );
};

export const FadeInDiv = ({ className, tabs, active }) => {
  return (
    <div className={cn('relative w-full', className)}>
      {tabs.map((tab, idx) => (
        <motion.div
          key={tab.value}
          layoutId={tab.value}
          initial={{ opacity: 0, y: 10 }}
          animate={{
            opacity: active.value === tab.value ? 1 : 0,
            y: active.value === tab.value ? 0 : 10,
          }}
          transition={{ duration: 0.3 }}
          className={cn(
            'absolute inset-0 w-full',
            active.value === tab.value ? 'block' : 'hidden'
          )}
        >
          {tab.content}
        </motion.div>
      ))}
    </div>
  );
};
