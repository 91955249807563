import React, { useEffect } from 'react';
import MainLayout from '../main/mainLayout';
import HomeHead from './section/head';
import HomeServices from './section/services';
import HomeProjects from './section/projets';
import HomePrice from './section/price';
import HomeFaq from './section/faq';
import HomeContact from './section/contact';
import { Helmet } from 'react-helmet';

function Home() {
  const handleSectionNavigate = (id) => {
    setTimeout(() => {
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }, 100); // Temporisation pour attendre le rendu du DOM
  };

  useEffect(() => {
    const hash = window.location.hash.substring(1);
    if (hash) {
      handleSectionNavigate(hash);
    }
  }, []);

  return (
    <MainLayout onSectionNavigate={handleSectionNavigate}>
      <Helmet>
        <title>Théo Vesque - Développeur web Fullstack freelance</title>
        <meta
          name="description"
          content="Développeur web freelance, spécialisé dans la conception et le développement de sites web sur mesure, j'aide les entreprises et particuliers à se démarquer en ligne grâce à des solutions personnalisées. De la conception initiale au déploiement final, je travaille en étroite collaboration avec mes clients pour garantir leur satisfaction."
        />
        <meta name="keywords" content="accueil, site, react" />
      </Helmet>
      <section id="home">
        <HomeHead />
      </section>
      <section id="services">
        <HomeServices />
      </section>
      <section id="projects">
        <HomeProjects />
      </section>
      <section id="pricing">
        <HomePrice />
      </section>
      <HomeFaq />
      <section id="devis">
        <HomeContact />
      </section>
    </MainLayout>
  );
}

export default Home;
