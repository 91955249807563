import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

function Header({ onSectionNavigate }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleScrollTo = (id) => {
    if (location.pathname === '/') {
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
        setIsMenuOpen(false);
      }
    } else {
      navigate('/');
      if (typeof onSectionNavigate === 'function') {
        onSectionNavigate(id);
      }
      setIsMenuOpen(false);
    }
  };

  return (
    <div className="fixed left-0 right-0 top-0 z-50  bg-white shadow-md">
      <div className="mx-auto flex max-w-screen-xl flex-wrap items-center justify-between p-4">
        <a href="#" className="flex items-center space-x-3 rtl:space-x-reverse">
          <img
            src="../assets/images/logo.png"
            className="w-20"
            alt="Mon logo"
          />
        </a>
        <div className="flex space-x-3 md:order-2 md:space-x-0 rtl:space-x-reverse">
          <button
            type="button"
            className="rounded-lg bg-darkBlue px-4 py-2 text-center text-sm font-medium text-white hover:bg-midBlue hover:font-semibold focus:outline-none"
            onClick={() => handleScrollTo('devis')}
          >
            Demander un devis
          </button>
          <button
            type="button"
            className="inline-flex h-10 w-10 items-center justify-center rounded-lg p-2 text-sm text-darkBlue hover:bg-zinc-100 focus:outline-none focus:ring-2 focus:ring-zinc-200 md:hidden dark:text-zinc-400 dark:hover:bg-darkBlue dark:focus:ring-darkBlue"
            onClick={toggleMenu}
          >
            <span className="sr-only">Open main menu</span>
            <svg
              className="h-5 w-5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 17 14"
            >
              <path
                stroke="currentColor"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M1 1h15M1 7h15M1 13h15"
              />
            </svg>
          </button>
        </div>
        <div
          className={`w-full items-center justify-between md:order-1 md:flex md:w-auto ${
            isMenuOpen ? '' : 'hidden'
          }`}
          id="navbar-cta"
        >
          <ul className="md mt-4 flex flex-col rounded-lg border border-zinc-100 bg-darkBlue p-4 font-medium md:mt-0 md:flex-row md:space-x-8 md:border-0 md:bg-transparent md:p-0 rtl:space-x-reverse dark:border-zinc-700">
            <li>
              <button
                onClick={() => handleScrollTo('home')}
                className="block w-full rounded px-3 py-2 text-left text-white md:bg-transparent md:p-0 md:px-4 md:py-2 md:text-zinc-900 md:hover:rounded-md dark:hover:bg-lightBlue dark:hover:text-zinc-900"
                aria-current="page"
              >
                Accueil
              </button>
            </li>
            <li>
              <button
                onClick={() => handleScrollTo('services')}
                className="block w-full rounded px-3 py-2 text-left text-white md:bg-transparent md:p-0 md:px-4 md:py-2 md:text-zinc-900 md:hover:rounded-md dark:hover:bg-lightBlue dark:hover:text-zinc-900"
              >
                Services
              </button>
            </li>
            <li>
              <button
                onClick={() => handleScrollTo('projects')}
                className="block w-full rounded px-3 py-2 text-left text-white md:bg-transparent md:p-0 md:px-4 md:py-2 md:text-zinc-900 md:hover:rounded-md dark:hover:bg-lightBlue dark:hover:text-zinc-900"
              >
                Projets
              </button>
            </li>
            <li>
              <button
                onClick={() => handleScrollTo('pricing')}
                className="block w-full rounded px-3 py-2 text-left text-white md:bg-transparent md:p-0 md:px-4 md:py-2 md:text-zinc-900 md:hover:rounded-md dark:hover:bg-lightBlue dark:hover:text-zinc-900"
              >
                Tarifs
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Header;
