import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';

function HomeContact() {
  const form = useRef();
  const [successMessage, setSuccessMessage] = useState('');

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        'service_a9bnucw',
        'template_soh9fdu',
        form.current,
        'Sj1US-VHQBa2CtxPX'
      )
      .then(
        () => {
          setSuccessMessage('Message envoyé avec succès!');
          form.current.reset();
          setTimeout(() => setSuccessMessage(''), 3000); // Cacher le message après 3 secondes
        },
        (error) => {
          console.log('FAILED...', error.text);
        }
      );
  };

  return (
    <div className="mt-16 w-full bg-darkBlue px-8 py-2 lg:px-36 lg:py-8">
      <h1 className="mb-8 text-2xl font-semibold text-white">
        Demander un devis
      </h1>
      {successMessage && (
        <div className="fixed right-4 top-4 rounded-lg bg-green-500 p-3 text-white shadow-lg">
          {successMessage}
        </div>
      )}
      <form ref={form} onSubmit={sendEmail}>
        <div className="mb-6 flex w-full flex-wrap justify-between space-y-6 md:space-y-0 lg:space-x-4">
          <div className="relative w-full lg:w-1/4">
            <label
              htmlFor="hs-floating-input-name"
              className="absolute left-2 top-2 text-sm text-gray-500 dark:text-white"
            >
              Nom / entreprise
            </label>
            <input
              type="text"
              id="hs-floating-input-name"
              className="block w-full rounded-lg border border-darkBlue p-4 text-sm"
              name="from_name"
              placeholder="Nom / entreprise"
            />
          </div>

          <div className="relative w-full lg:w-1/4">
            <label
              htmlFor="hs-floating-input-email"
              className="absolute  top-8 text-sm text-gray-500 dark:text-white"
            >
              Email
            </label>
            <input
              type="email"
              id="hs-floating-input-email"
              className="block w-full rounded-lg border border-darkBlue p-4 text-sm"
              name="from_email"
              placeholder="Email"
            />
          </div>

          <div className="relative w-full lg:w-1/4">
            <label
              htmlFor="hs-floating-input-budget"
              className="absolute  top-8 text-sm text-gray-500 dark:text-white"
            >
              Votre budget
            </label>
            <input
              type="text"
              id="hs-floating-input-budget"
              className="block w-full rounded-lg border border-darkBlue p-4 text-sm"
              name="from_budget"
              placeholder="Votre budget"
            />
          </div>
        </div>
        <label
          htmlFor="hs-floating-input-message"
          className="mb-2 block text-sm font-medium text-gray-900 dark:text-white"
        >
          Message
        </label>
        <textarea
          id="hs-floating-input-message"
          name="message"
          className="block h-40 w-full rounded-lg border border-darkBlue p-4 text-sm"
          placeholder="Explication de votre projet"
        />
        <input
          type="submit"
          value="Envoyer"
          className="mb-4 mt-8 cursor-pointer rounded-lg bg-white p-2 text-darkBlue hover:bg-midBlue  hover:font-semibold lg:mb-0"
        />
      </form>
    </div>
  );
}

export default HomeContact;
