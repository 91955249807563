import React, { useState } from 'react';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CallIcon from '@mui/icons-material/Call';
import MailIcon from '@mui/icons-material/Mail';
import { SVGProps } from 'react';

function Footer() {
  return (
    <footer className="bg-lightBlue">
      <div className="lgpy-8 flex-col justify-center px-8 py-4 align-middle md:flex-col lg:flex lg:flex-row lg:px-36">
        <div className="w-full lg:w-1/2 ">
          <h2 className="mb-4 text-xl font-semibold">
            Développeur web freelance
          </h2>
          <p className="w-5/6">
            Développeur web freelance, je suis disponible pour répondre à tous
            types de projets. Que ce soit des sites vitrines, e-commerce, ou des
            applications plus spécifiques. <br /> N'hésitez pas à me contacter
            pour plus de détail
          </p>
        </div>
        <div className="mt-12 flex w-full flex-col justify-center space-y-3 lg:ml-24 lg:mt-0 lg:w-1/2">
          <div className="flex space-x-4">
            <LocationOnIcon className="text-darkBlue" />
            <p>Maubeuge - Nord (59)</p>
          </div>
          <div className="flex space-x-4">
            <MailIcon className="text-darkBlue" />
            <p>vesquetheo1@gmail.com</p>
          </div>
          <div className="flex space-x-4">
            <CallIcon className="text-darkBlue" />
            <p>07 83 49 71 66</p>
          </div>
          <div className="align-center flex space-x-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="28px"
              height="28px"
              viewBox="0 0 24 24"
            >
              <path
                fill="#003140"
                d="M19 3a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2zm-.5 15.5v-5.3a3.26 3.26 0 0 0-3.26-3.26c-.85 0-1.84.52-2.32 1.3v-1.11h-2.79v8.37h2.79v-4.93c0-.77.62-1.4 1.39-1.4a1.4 1.4 0 0 1 1.4 1.4v4.93zM6.88 8.56a1.68 1.68 0 0 0 1.68-1.68c0-.93-.75-1.69-1.68-1.69a1.69 1.69 0 0 0-1.69 1.69c0 .93.76 1.68 1.69 1.68m1.39 9.94v-8.37H5.5v8.37z"
              />
            </svg>
            <a href="https://www.linkedin.com/in/th%C3%A9o-vesque-688105214/">
              Linkedin
            </a>
          </div>
        </div>
      </div>
      <p className="mt-4 w-full pb-2 text-center">
        Théo Vesque ©2024 Tous droits réservés
      </p>
    </footer>
  );
}

export default Footer;
