import React from 'react';
import { HeroHighlight, Highlight } from '../../../component/HeroHighlight'; // Mettez à jour le chemin selon votre structure de projet

function HomeHead() {
  return (
    <div className="mt-16 flex h-fit flex-col">
      <div className="absolute w-full px-4 lg:mt-12 lg:px-36">
        <HeroHighlight containerClassName="absolute">
          <h1 className="w-full	text-xl font-bold text-zinc-900 md:text-3xl lg:text-4xl">
            Théo VESQUE - <Highlight>Développeur web Freelance</Highlight>
          </h1>
        </HeroHighlight>
        <h2 className="mt-16 text-base font-medium text-zinc-900 md:mt-16 md:text-xl lg:mt-16 lg:text-2xl">
          Conception de Sites Web Adaptés à Vos Besoins
        </h2>
        <p className="text-md mt-8 w-3/4 text-zinc-900/80 md:w-1/2 lg:w-1/2">
          Spécialisé dans la conception et le développement de sites web sur
          mesure, j'aide les entreprises et particuliers à se démarquer en ligne
          grâce à des solutions personnalisées. De la conception initiale au
          déploiement final, je travaille en étroite collaboration avec mes
          clients pour garantir leur satisfaction.
        </p>
      </div>
      <div className="mt-80 flex items-end md:mt-12 lg:mt-0">
        <div className="h-[4.48vw] w-[50vw] bg-darkBlue text-transparent lg:h-[3.57vw] lg:w-[60vw]">
          -
        </div>
        <img
          className="w-[50vw] lg:w-[40vw]"
          src="assets/images/dev_gif.gif"
          alt="Développeur web"
        />
      </div>
      <div className="flex h-[12vh] w-full items-center justify-center bg-darkBlue text-transparent lg:justify-start lg:px-36"></div>
    </div>
  );
}

export default HomeHead;
