import React from 'react';
import { useParams } from 'react-router-dom';
import MainLayout from '../main/mainLayout';
import { HeroHighlight, Highlight } from '../../component/HeroHighlight';
import useScrollToTop from '../../hooks/useScrollToTop';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './project.css';
import sprite from './sprite.svg';

// Données statiques pour les projets
const projects = [
  {
    id: 'landing-saas',
    title: "Page d'accueil pour un logiciel",
    description:
      'Le but de ce projet était dintégrer une maquette pour un logiciel.',
    technos: ['React', 'Tailwind'],
    images: [
      '../assets/images/projects/front/landing-saas/landing-saas-mockup.png',
      '../assets/images/projects/front/landing-saas/landing-saas-1.png',
    ],
  },
  {
    id: 'orm',
    title: "Design d'un dashboard pour un ORM",
    description:
      "L'objectif dans ce projet a été le design d'un dashboard de CRM en suivant un cahier des charges précis.",
    technos: ['Figma', 'UI/UX', 'Web design'],
    images: ['../assets/images/projects/front/orm/orm-mockup.png'],
  },
  {
    id: 'pomodoro',
    title: 'Timer pomodoro',
    description:
      "Le but de ce projet était la création d'un timer pomodoro, qui est une méthode de travail pour gagner en productivité.",
    technos: ['React', 'Tailwind'],
    images: [
      '../assets/images/projects/front/pomodoro/pomodoro-mockup.png',
      '../assets/images/projects/front/pomodoro/pomodoro1.png',
      '../assets/images/projects/front/pomodoro/pomodoro2.png',
    ],
  },
  {
    id: 'imc-calculator',
    title: "Calculatrice d'imc",
    description:
      "Le but de ce projet a été la création d'un outil permettant de calculer son IMC.",
    technos: ['HTML', 'CSS', 'JavaScript'],
    images: [
      '../assets/images/projects/front/imc/imc-mockup.png',
      '../assets/images/projects/front/imc/imc1.png',
      '../assets/images/projects/front/imc/imc2.png',
      '../assets/images/projects/front/imc/imc3.png',
      '../assets/images/projects/front/imc/imc4.png',
    ],
  },

  {
    id: 'extranet',
    title: 'Extranet de suivi clients',
    description:
      "Le but de ce projet a été la réalisation d'un extranet permettant le suivi client. On a la possibilité de consulter les informations sur différents dashboards. Par exemple pour les utilisateurs, équipements (Ordinateurs, pare feu, ...), les machines virtuelles ou encore les serveurs. Les données sont mises en forme de manière claire et concise grâce à des graphes et des chiffres.",
    technos: ['Python', 'FastAPI', 'Angular', 'PostGreSQL', 'Docker'],
    images: [
      '../assets/images/projects/back/extranet/extranet-mockup.png',
      '../assets/images/projects/back/extranet/dashboard.png',
    ],
  },
  {
    id: 'sport-blog',
    title: 'Blog de sport ',
    description:
      'Blog lié au sport avec différents outils permettant de suivre son évolution.',
    technos: ['Symfony', 'PHP', 'MySQL'],
    images: [
      '../assets/images/projects/back/sport/blog_sport-mockup.png',
      '../assets/images/projects/back/sport/blog_sport1.png',
      '../assets/images/projects/back/sport/blog_sport2.png',
      '../assets/images/projects/back/sport/blog_sport3.png',
    ],
  },
  {
    id: 'quiz',
    title: 'Quiz en ligne',
    description: 'Application de quiz en ligne sur différents thèmes',
    technos: ['PHP', 'Javascript', 'MySQL'],
    images: [
      '../assets/images/projects/back/quiz/quiz-mockup.png',
      '../assets/images/projects/back/quiz/quiz1.png',
      '../assets/images/projects/back/quiz/quiz2.png',
      '../assets/images/projects/back/quiz/quiz3.png',
    ],
  },
  {
    id: 'robindesbus',
    title: 'Site pour une association de transport',
    description:
      "Développement d'un site pour une association de transports. L'objectif était de permettre aux clients de générer des devis instantanés pour avoir une idée de prix pour leurs voyages en bus.",
    technos: ['PHP', 'Javascript', 'MySQL'],
    images: [
      '../assets/images/projects/back/robindesbus/robindesbus-mockup.png',
      '../assets/images/projects/back/robindesbus/robindesbus1.png',
      '../assets/images/projects/back/robindesbus/robindesbus2.png',
      '../assets/images/projects/back/robindesbus/robindesbus3.png',
      '../assets/images/projects/back/robindesbus/robindesbus4.png',
    ],
  },
];

function Project() {
  useScrollToTop();

  // Récupérer le paramètre d'URL (:id)
  let { id } = useParams();

  // Trouver le projet correspondant à l'ID
  const project = projects.find((project) => project.id === id);

  if (!project) {
    return <div>Projet non trouvé</div>;
  }

  return (
    <MainLayout>
      <div className="mt-16 flex flex-col justify-between px-16 md:flex-col lg:flex-row">
        <div className="carousel-container mb-16 w-[70vw] md:mb-16 md:w-[70vw] lg:w-[40vw]">
          <Carousel
            className="custom-carousel" // Ajoutez une classe personnalisée ici
            showArrows={true}
            showThumbs={false}
            infiniteLoop={true}
            autoPlay={true}
            renderArrowNext={(clickHandler, hasNext) => {
              return (
                hasNext && (
                  <button
                    className="nav_btn nav_btn_right"
                    onClick={clickHandler}
                  >
                    <svg>
                      <use xlinkHref={sprite + '#right'}></use>
                    </svg>
                  </button>
                )
              );
            }}
            renderArrowPrev={(clickHandler, hasPrev) => {
              return (
                hasPrev && (
                  <button
                    className="nav_btn nav_btn_left"
                    onClick={clickHandler}
                  >
                    <svg>
                      <use xlinkHref={sprite + '#left'}></use>
                    </svg>
                  </button>
                )
              );
            }}
          >
            {project.images.map((image, index) => (
              <div key={index}>
                <img
                  src={image}
                  alt={`Image ${project.title} ${index}`}
                  className=""
                />
              </div>
            ))}
          </Carousel>
        </div>

        <div className="flex w-full w-full flex-col md:w-full lg:w-1/2">
          <HeroHighlight containerClassName="mb-8">
            <h1 className="w-full text-xl font-bold text-zinc-900 md:text-3xl lg:text-4xl">
              <Highlight>{project.title}</Highlight>
            </h1>
          </HeroHighlight>
          <p>{project.description}</p>
          <div>
            <ul className="flex flex-wrap">
              {project.technos.map((techno, index) => (
                <li
                  className="my-2 mr-4 rounded-lg bg-darkBlue px-4 py-1 text-white"
                  key={index}
                >
                  {techno}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </MainLayout>
  );
}

export default Project;
